
import context from '@truckdown/components-systems';
import { IUserContext } from '@truckdown/systems';
import registerTools from '@truckdown/tools';

import './Services';
import './Images';
import './Components';

export { AddRatingModel } from './Services/AddRatingModel';
export type { ApiResponseModel } from './Services/ApiResponseModel';
export { ForwardLocationModel } from './Services/ForwardLocationModel';
export type { RatingModel } from './Services/RatingModel';
export type { RatingNoteModel } from './Services/RatingNoteModel';
export { UpdateInfoModel } from './Services/UpdateInfoModel';

export type { IListingService } from './Services/IListingService';

export default {
    registerPrerequisites: (context: IUserContext) => {
        registerTools(context);
    }
};
